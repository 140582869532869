import {
  Empty,
  message,
  Skeleton,
  Tabs,
  Typography,
  Button,
  Alert,
  Flex
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SendOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import Location from 'src/components/project/explore/Location';
import Pricing from 'src/components/project/explore/Pricing';
import Specifications from 'src/components/project/explore/Specifications';
import emptyExplore from 'src/images/exploreEmpty.png';
import emptyState from 'src/images/emptyState.svg';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { SpecMinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';
import { useLatestRelevantPriceForExplore } from 'src/components/analytics/MarketPriceByFormLineChart';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { currencyValuePrice } from 'src/utils/currency';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProjectHeader from 'src/components/project/ProjectHeader';
import { labelMap } from 'src/components/project/explore/Suppliers';
import { useUser, useUserIsInGroup } from 'src/utils/authentication';
import { submitInterestingUserActivity, updateProject } from 'src/Mutation';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useSupplierList from './hooks/useSupplierList';
import useIsBalesMode from './hooks/useIsBalesMode';
import useDetailedSupplierCountBreakdown from './hooks/useDetailedSupplierCountBreakdown';
import BalesExplore from './BalesExplore';

export function ConditionalContent() {
  const filters = useRelevantFiltersForForm();
  const noFilters = Object.keys(filters).length === 0;
  return noFilters ? <EmptyExplore /> : <Content />;
}

const EMPTY_ARRAY = [];

export default function Content() {
  const [_, setFilters] = useConciergeContextState(['explore', 'filters']);
  const [activeKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);
  const filters = useRelevantFiltersForForm();
  const { form_code__in: form, type_code__in: type } = filters || {};

  const isBales = useIsBalesMode();

  // Returns an array of kpi codes
  const primaryKpis = usePrimaryKpis();

  const { activeProjectId: projectId, activeProject } = useSidebarContext();

  const { suppliers, isLoadingSuppliers } = useSupplierList({
    filterSupplierLabels: labelMap.all, // We need this to exclude removed suppliers from count
    projectId
  });

  const latestPriceData = useLatestRelevantPriceForExplore();

  const isLoading = isLoadingSuppliers;

  const detailedCounts = suppliers?.detailed_counts || EMPTY_ARRAY;
  const { tdsCountBase, supplierCountBase, supplierCountUnverified } =
    useDetailedSupplierCountBreakdown(detailedCounts);
  const isConcierge = useIsConcierge();

  const recordCount = tdsCountBase;

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);

      navigate(`/project/source/${response.uuid}/prioritize-suppliers`);
    },
    onError: (e) => {
      messageApi.error('Error updating project. Please try again.', 8);
      // Send error to Sentry
      captureException(e);
    }
  });
  const { data: user } = useUser();
  const canSource = useUserIsInGroup('Source');

  const { mutate: mutateNotify, isMutating: isMutatingCreate } = useMutation({
    mutationFn: submitInterestingUserActivity,
    onSuccess: () => messageApi.info('Your request has been submitted.'),
    onError: () => {
      messageApi.error('Error submitting your request.');
    }
  });

  if (isLoading && !suppliers) return <Skeleton />;
  if (!form || !type) return <EmptyExplore />;

  const drfqSent = ['search', 'procure', 'manage'].includes(
    activeProject?.stage
  );

  const resetFilters = () => {
    setFilters({});
  };

  const requestMoreData = () => {
    const messageString = `A request for sourcing new data was made with these requirements: ${JSON.stringify(
      filters
    )}`;
    mutateNotify({ user: user.id, message: messageString });
  };

  if (!isConcierge && recordCount < 15)
    return (
      <>
        {contextHolder}
        <LowDataExplore
          resetFilters={resetFilters}
          requestMoreData={requestMoreData}
        />
      </>
    );

  return (
    <>
      {contextHolder}
      <ProjectHeader>
        <Typography.Text strong>
          {`You found ${
            supplierCountBase ? prettyNumberRound(supplierCountBase) : '-'
          } suppliers${
            isBales
              ? ` and ${prettyNumberRound(
                  Math.max(0, supplierCountUnverified - supplierCountBase)
                )} unverified suppliers`
              : ''
          }`}
        </Typography.Text>
        {activeProject?.stage &&
          (drfqSent ? (
            <Link to={`/project/source/${projectId}/quotes`}>
              <Button>
                <SendOutlined rotate={-45} />
                View in Source
              </Button>
            </Link>
          ) : (
            <Button
              loading={updating}
              disabled={!canSource}
              type="primary"
              size="large"
              htmlType="button"
              onClick={() => {
                updateProjectMutation({
                  uuid: projectId,
                  stage: 'search'
                });
              }}
            >
              {updating ? 'Loading....' : 'Send Digital RFQ'}
            </Button>
          ))}
      </ProjectHeader>
      {drfqSent && (
        <Alert
          showIcon
          className="mb-sm"
          message={
            <>
              Your Digital RFQ has been sent to {activeProject?.tdss?.length}{' '}
              supplier(s). Changes here will not be saved.
            </>
          }
          action={
            <Link to="/project/explore/playground/?new=1">
              <Typography.Text className="link-style ml-xs" strong>
                Create a new project
              </Typography.Text>
            </Link>
          }
        />
      )}
      {isBales ? (
        <BalesExplore />
      ) : (
        <div className="content explore-content">
          <Tabs
            className="bordered-tabs"
            items={[
              {
                key: 'specs',
                label: (
                  <TabTitle
                    title="Specifications"
                    subtitle={primaryKpis.map((kpiCode, i) => (
                      <React.Fragment key={kpiCode}>
                        {kpiCode} <SpecMinMaxRangeString kpiCode={kpiCode} />
                        {i < primaryKpis.length - 1 && ' • '}
                      </React.Fragment>
                    ))}
                  />
                ),
                children: (
                  <Specifications
                    kpiTabs={activeKpiFilters}
                    filters={filters}
                  />
                )
              },
              {
                key: 'suppliers',
                label: (
                  <TabTitle
                    title="Suppliers"
                    subtitle={`${
                      supplierCountBase
                        ? prettyNumberRound(supplierCountBase)
                        : '-'
                    } Suppliers • ${
                      recordCount ? prettyNumberRound(recordCount) : '-'
                    } Records`}
                  />
                ),
                children: (
                  <Location
                    locked // We can't edit supplier list from Explore
                  />
                )
              },
              {
                key: 'market',
                label: (
                  <TabTitle
                    title="Market"
                    subtitle={
                      latestPriceData &&
                      `${currencyValuePrice(
                        latestPriceData.price,
                        latestPriceData.currency,
                        latestPriceData.units
                      )} (${latestPriceData.label})`
                    }
                  />
                ),
                children: <Pricing />
              }
            ]}
          />
        </div>
      )}
    </>
  );
}

function EmptyExplore() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Empty
        style={{ margin: 'auto auto' }}
        image={emptyExplore}
        description=""
      >
        <Typography.Title level={2}>Explore what's possible</Typography.Title>
        <Typography.Paragraph>
          Leverage Circular's database of over 50,000 records to get a real
          picture of what is possible with PCR.
        </Typography.Paragraph>
      </Empty>
    </div>
  );
}

function LowDataExplore({ resetFilters, requestMoreData }) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Empty
        className="low-data-explore"
        style={{ margin: 'auto auto' }}
        image={emptyState}
        description=""
      >
        <Typography.Title className="mt-xl" level={2}>
          Not enough data
        </Typography.Title>
        <Typography.Paragraph
          className="mt-sm font-size-3a"
          style={{ maxWidth: '523px' }}
        >
          We currently have low data for this material. Request more data below
          and we&apos;ll prioritize acquiring it and keep you updated when
          it&apos;s available!
        </Typography.Paragraph>
        <Flex
          className="mt-lg"
          direction="horizontal"
          gap={16}
          justify="center"
        >
          <Button className="font-size-3" type="default" onClick={resetFilters}>
            Reset Filters
          </Button>
          <Button
            className="font-size-3"
            type="primary"
            onClick={requestMoreData}
          >
            Request More Data
          </Button>
        </Flex>
      </Empty>
    </div>
  );
}
LowDataExplore.propTypes = {
  resetFilters: PropTypes.func,
  requestMoreData: PropTypes.func
};

function TabTitle({ title, subtitle }) {
  return (
    <>
      <span className="font-size-4">{title}</span>
      <br />
      <Typography.Text
        type="secondary"
        className="font-size-2"
        style={{ fontWeight: 400 }}
      >
        {subtitle}
      </Typography.Text>
    </>
  );
}
TabTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
