import { CaretDownOutlined } from '@ant-design/icons';
import { Badge, Divider, Row, Skeleton, Slider, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  totalCost,
  totalCostTarget
} from 'src/components/project/source/TdsTable';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import { currencySymbol, currencyValuePrice } from 'src/utils/currency';

const getMinQuote = (tdss) =>
  tdss
    ? Math.min(...tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost))
    : null;
const getMaxQuote = (tdss) =>
  tdss
    ? Math.max(...tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost))
    : null;
const getAvgQuote = (tdss) => {
  if (!tdss) return null;

  const costs = tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost);

  if (!costs.length) return null;

  return costs.reduce((sum, cost) => sum + cost, 0) / costs.length;
};

export default function QuoteComparison() {
  const { projectTdsId } = useParams();
  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );
  const activeQuote = useMemo(
    () => projectTds && totalCost(projectTds),
    [projectTds]
  );
  const currency = useMemo(() => projectTds?.project_currency, [projectTds]);
  const units = useMemo(() => projectTds?.project_weight_units, [projectTds]);

  const target = useMemo(
    () =>
      filteredRecords?.meta_data?.project &&
      totalCostTarget(filteredRecords?.meta_data?.project),
    [filteredRecords]
  );
  const noPriceString = useMemo(
    () => `${currencySymbol(currency)} -- /${units}`,
    [currency, units]
  );

  const minQuote = useMemo(
    () => getMinQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const maxQuote = useMemo(
    () => getMaxQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const avgQuote = useMemo(
    () => getAvgQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const padding = useMemo(
    () => (maxQuote - minQuote) * 0.02,
    [minQuote, maxQuote]
  );

  if (!projectTds) return <Skeleton />;

  return (
    <div className="quotes-comparison">
      <Row>
        <Typography.Text className="lg heavy">Quote</Typography.Text>
      </Row>
      <Typography.Paragraph className="xxl heavy mb-0 poppins">
        {activeQuote
          ? currencyValuePrice(activeQuote, currency, units)
          : noPriceString}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {projectTds.price?.price
          ? currencyValuePrice(projectTds.price?.price, currency, units)
          : noPriceString}
      </Typography.Paragraph>
      <Divider />
      <Row justify="space-between">
        <Typography.Text strong>Quote Comparison</Typography.Text>
        <Typography.Text type="secondary" className="sm">
          1 of {filteredRecords.results.length} Quotes
        </Typography.Text>
      </Row>
      <Typography.Paragraph className="mb-xl">
        {target ? (
          <Badge
            color="green"
            text={`Target: ${currencyValuePrice(target, currency, units)}`}
          />
        ) : (
          'No target set'
        )}
      </Typography.Paragraph>
      {/* Using Slider allows us to easily place arbitrary points within range */}
      <Slider
        className="mx-0 mb-xs"
        disabled
        value={maxQuote}
        min={minQuote - padding}
        max={maxQuote + padding}
        style={{ pointerEvents: 'none' }}
        styles={{
          rail: {
            background:
              'linear-gradient(90deg, #A4B5FB 0%, #2F54EB 50.05%, #0727A6 100%)',
            height: 8,
            borderRadius: 4
          },
          handle: {
            display: 'none'
          },
          track: {
            display: 'none'
          },
          tracks: {
            display: 'none'
          }
        }}
        marks={{
          [activeQuote]: activeQuote ? (
            <CaretDownOutlined className="caret-mark" />
          ) : null,
          [target]: target ? (
            <CaretDownOutlined className="caret-mark target" />
          ) : null,
          [avgQuote]: <div className="avg-mark">Avg.</div>
        }}
      />
      <Row justify="space-between">
        <Typography.Text>
          {currencyValuePrice(minQuote, currency)}
        </Typography.Text>
        <Typography.Text>
          {currencyValuePrice(maxQuote, currency)}
        </Typography.Text>
      </Row>
    </div>
  );
}
