import { Skeleton } from 'antd';
import React from 'react';

import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import MarketPriceByFormLineChartWithControls from 'src/components/analytics/MarketPriceByFormLineChartWithControls';

export default function PricingHistory() {
  const { activeProject } = useSidebarContext();

  if (!activeProject) return <Skeleton />;

  return (
    <div
      className="pricing-history price-history-chart__panel"
      style={{ height: 390 }}
    >
      <MarketPriceByFormLineChartWithControls filters={activeProject.filters} />
    </div>
  );
}
