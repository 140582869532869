import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import SupplierCapabilityBarChart from 'src/components/project/explore/charts/SupplierCapabilityBarChart';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import MarketPriceByFormLineChartWithControls from 'src/components/analytics/MarketPriceByFormLineChartWithControls';

const EMPTY_OPTIONS = {};

export default function Pricing() {
  const filters = useRelevantFiltersForForm() || EMPTY_OPTIONS;
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  return (
    <ExploreSection className="pricing-section">
      <Typography.Paragraph strong className="font-size-4 mb-lmd">
        Pricing History
      </Typography.Paragraph>
      <Row gutter={16} wrap={false}>
        <Col span={14}>
          <ExplorePanel className="price-history-chart__panel" pad>
            <MarketPriceByFormLineChartWithControls
              filters={filters}
              priceSettings={projectSettings?.prices}
            />
          </ExplorePanel>
        </Col>
        <Col span={10}>
          <SupplierCapabilityBarChart />
        </Col>
      </Row>
    </ExploreSection>
  );
}
