import React, { createContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Flex, Input } from 'antd';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import CheckedPrimary from 'src/components/icons/CheckedPrimary';
import CirclePlain from 'src/components/icons/CirclePlain';

export const SidebarMenuContext = createContext();

export default function SidebarCascade({
  title,
  items,
  children,
  selectItem,
  allowSearch,
  light,
  back,
  ...props
}) {
  const [search, setSearch] = useState();
  return (
    <div
      className={`project-sidebar-cascade ${light ? 'light' : ''}`}
      {...props}
    >
      {back && (
        <div className="project-sidebar-cascade--back pb-xs">
          <button className="bare" type="button" onClick={back.action}>
            <ArrowLeftOutlined /> {back.label}
          </button>
        </div>
      )}
      <div className="project-sidebar-cascade--title pb-sm">
        {typeof title === 'string' ? (
          <Typography.Text className="xl heavy">{title}</Typography.Text>
        ) : (
          title
        )}
      </div>
      {allowSearch && (
        <div className="project-sidebar-cascade--search pt-xs">
          <Input
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search projects"
          />
        </div>
      )}
      {items && (
        <div className="project-sidebar-cascade--items">
          {items
            .filter(
              (item) => !allowSearch || !search || item.label.includes(search)
            )
            .map((item, index) => (
              <SidebarItem
                kids={item.steps}
                selected={item.active}
                stepkey={item.key}
                key={item.key}
                label={item.label}
                icon={item.icon}
                component={item.component}
                next={item.next}
                // onSelect={() => selectItem(item, index)}
                onSelect={selectItem}
              />
            ))}
        </div>
      )}
      {children}
    </div>
  );
}
SidebarCascade.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array,
  children: PropTypes.node,
  selectItem: PropTypes.func,
  allowSearch: PropTypes.bool,
  light: PropTypes.bool,
  back: PropTypes.object
};

function SidebarItem({
  kids,
  label,
  stepkey,
  next,
  component,
  onSelect,
  selected,
  icon
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const hasActiveSubitem = useMemo(
    () => kids && kids.some((subitem) => subitem.active),
    [kids]
  );

  useEffect(() => {
    if (hasActiveSubitem) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [hasActiveSubitem]);

  return (
    <>
      <div
        className={`project-sidebar-item ${selected ? 'selected' : ''} ${
          kids ? '' : ''
        }`}
        role="presentation"
        onClick={kids ? () => toggleOpen() : () => onSelect({ key: stepkey })}
      >
        <Flex justify="space-between" gap={8}>
          {component || (
            <Typography.Text ellipsis={{ tooltip: label }}>
              {icon ? <>{icon}&nbsp;&nbsp;</> : undefined}
              {label}
            </Typography.Text>
          )}
          {next ? <RightOutlined /> : undefined}
        </Flex>
      </div>
      {kids && (
        <div
          className={`project-sidebar-cascade--sub-items ${
            isOpen ? 'open' : ''
          }`}
        >
          {kids.map((item, index) => (
            <SidebarItem
              selected={item.active}
              stepkey={item.key}
              key={item.key}
              label={item.label}
              component={item.component}
              next={item.next}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </>
  );
}
SidebarItem.propTypes = {
  kids: PropTypes.array,
  label: PropTypes.string,
  next: PropTypes.bool,
  selected: PropTypes.bool,
  component: PropTypes.node,
  icon: PropTypes.node,
  onSelect: PropTypes.func,
  stepkey: PropTypes.string
};

export function ProjectStep({ step }) {
  return (
    <div className="project-sidebar-step">
      {step.done ? <CheckedPrimary /> : <CirclePlain />}
      &nbsp;
      <Typography.Text ellipsis={{ tooltip: step.label }}>
        {step.label}
      </Typography.Text>
      <div></div>
    </div>
  );
}
ProjectStep.propTypes = {
  step: PropTypes.object
};

export function ProjectSubStep({ step }) {
  return (
    <div className="project-sidebar-step">
      <Typography.Text ellipsis={{ tooltip: step.label }}>
        {step.label}
      </Typography.Text>
    </div>
  );
}

ProjectSubStep.propTypes = {
  step: PropTypes.object
};
