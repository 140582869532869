import { Col, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import QuoteComparison from 'src/components/project/procure/QuoteComparison';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import PricingHistory from 'src/components/project/procure/PrcingHistory';
import SupplierDetails from 'src/components/project/procure/SupplierDetails';

export default function SingleProjectTds() {
  const { projectTdsId } = useParams();
  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );

  return (
    <div className="search-results">
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            <Typography.Text type="secondary">Procure / </Typography.Text>
            {projectTds?.company_name}
          </Typography.Title>
        </Col>
      </Row>
      <Row wrap={false} gutter={[16, 0]}>
        <Col span={8}>
          <ExplorePanel solid className="mb-lg">
            <QuoteComparison />
          </ExplorePanel>
          <ExplorePanel pad className="mb-lg">
            <PricingHistory />
          </ExplorePanel>
          <ExplorePanel pad className="mb-lg">
            <SupplierDetails />
          </ExplorePanel>
        </Col>
        <Col span={16}></Col>
      </Row>
    </div>
  );
}
