import { Descriptions, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useProcureTdss from 'src/components/project/procure/useProcureTdss';

export default function SupplierDetails() {
  const { projectTdsId } = useParams();
  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );

  return (
    <div className="supplier-details">
      <Typography.Paragraph className="lg heavy mb-md mt-0">
        Supplier Details
      </Typography.Paragraph>
      <Descriptions
        colon={false}
        layout="vertical"
        labelStyle={{ paddingBottom: 0 }}
        column={1}
        items={[
          {
            key: 'contact_name',
            label: 'Primary Contact',
            children: 'Stephen Avery'
          },
          {
            key: 'email',
            label: 'Email',
            children: 'purchase@hummerl-recylcing.com'
          },
          {
            key: 'website',
            label: 'Website',
            children: (
              <a
                target="_blank"
                href="https://example.com"
                title="Supplier website"
              >
                www.hummel-recylcing.com
              </a>
            )
          },
          {
            key: 'phone',
            label: 'Phone',
            children: '+31 (0)20 2089958'
          },
          {
            key: 'address',
            label: 'Address',
            children: (
              <div>
                Post Office 10.05
                <br />
                3823 LH Amersfoot
                <br />
                Netherlands
              </div>
            )
          }
        ]}
      />
    </div>
  );
}
