import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import SidebarCascade from 'src/components/layout/sidebar/SidebarCascade';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import ManageProjectEllipsis from 'src/components/project/ManageProjectEllipsis';

export default function ProjectProcureWorkflow({ projectId }) {
  const { setActiveProjectId, activeProject } = useSidebarContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectItem = (step) => {
    navigate(`/project/procure/${projectId}/${step.key}`);
  };

  const { data: { results: tdss } = {} } = useProcureTdss();

  const cascadeItems = useMemo(
    () => [
      {
        component: <Typography.Text>Overview</Typography.Text>,
        key: '',
        active: pathname.match(new RegExp(`/${projectId}/$`))
      },
      ...(tdss?.map((tds) => ({
        component: <Typography.Text>{tds.company_name}</Typography.Text>,
        key: tds.project_tds_id,
        active: pathname.includes(`/${projectId}/${tds.project_tds_id}`)
      })) || [])
    ],
    [tdss, projectId, pathname]
  );

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  return (
    <SidebarCascade
      light
      title={
        activeProject ? (
          <>
            <Row
              wrap={false}
              align="top"
              gutter={[8, 0]}
              justify="space-between"
            >
              <Col style={{ marginRight: 'auto' }}>
                <Typography.Text className="xl heavy">
                  {activeProject?.name}
                </Typography.Text>
              </Col>
              <Col>
                <ManageProjectEllipsis />
              </Col>
            </Row>
            <Row>
              {activeProject?.updated_at ? (
                <Typography.Paragraph
                  className="font-size-2 mt-xxs mb-0"
                  type="secondary"
                >
                  Last Updated: {dayjs(activeProject?.updated_at).fromNow()}
                </Typography.Paragraph>
              ) : null}
            </Row>
          </>
        ) : (
          'Procure'
        )
      }
      items={cascadeItems}
      selectItem={selectItem}
    />
  );
}
ProjectProcureWorkflow.propTypes = {
  projectId: PropTypes.string
};
